import styled from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => theme.styles.cardWidth}
  color: ${({ theme }) => theme.colors.dark};
  flex-direction: column;
  display: flex;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: white;

  .nameContainer {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .label {
    font-size: 18px;
    line-height: 1.2;
    margin: 0;
  }
  .addressContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .icon {
    width: 50px;
    height: 50px;
    fill: #000000;
  }
`;

export const CardHeader = styled.header`
  display: flex;
  flex-direction: row;
  margin-bottom: 22px;
  align-items: center;
`;

export const TripTotalValue = styled.h1`
  color: #000;
  font-size: 30px;
`;

export const TripValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TripValues = styled.h2`
  color: #000;
  font-size: 15px;
`;

export const Seats = styled.div`
  font-size: 26px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 2px solid #000;
`;
